<template>
  <div class="viewContainer" >
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card v-if="loading" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8 text-center" elevation="0">
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-card>
      <v-card v-if="!loading" class="content-tabs-wrapper px-4 px-sm-8 py-4 py-sm-8" elevation="0">
        <div class="content-container">
          <div v-if="card !== null && !loading">
            <div class="d-flex justify-content-between">
              <v-btn 
                :to="{ name: 'customer.wallet' }"
                small
                rounded
                depressed 
                class="no-caps mb-4"
              >
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $t('back') }}
              </v-btn>
              
              <v-btn 
                small
                rounded
                depressed 
                class="no-caps mb-4"
                @click="refreshData"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </div>
            

            <v-card
              outlined
            >
              <v-img
                v-if="card.cover"
                :src="card.cover"
              />
              <v-card-title>
                <!-- <v-icon left color="ctaBg">
                  mdi-wallet-giftcard
                </v-icon>
                <span class="title font-weight-medium">{{ card.name }}</span> -->
                <v-avatar
                  color="grey"
                  size="48"
                >
                  <span class="white--text text-subtitle-1">{{ `${card.name.charAt(0).toUpperCase()}${card.name.charAt(1).toUpperCase()}` }}</span>
                </v-avatar>
                <span class="text-h6 ml-4">{{ card.name }}</span>
              </v-card-title>
              <v-card-text class="primary--text">
                <div class="d-flex align-baseline justify-space-between">
                  <div class="d-flex align-center text-h4 font-weight-medium">
                    <v-icon left size="inherit" class="primary--text">mdi-circle-multiple-outline</v-icon> {{ formatNumber(card.balance.points) }}
                  </div>
                  <div class="text-body-1 grey--text ml-4"><span v-html="formatCurrency(card.balance.value, card.balance.currency)"/></div>
                </div>
                <div class="text-subtitle-1 mt-4" v-if="card.short_description" v-html="card.short_description"/>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="primary--text pa-4">
                <div class="d-flex justify-space-between w-100">
                  <div class="">
                    <v-btn 
                      @click="TransferWallet('to_customer')" 
                      color="primary" 
                      rounded 
                      text
                      :small="$vuetify.breakpoint.xsOnly"
                      depressed 
                      class="no-caps"
                    >
                      {{ $t('give_friend') }}
                    </v-btn>
                  </div>
                  <div class="" v-if="exchange_points">
                    <v-btn 
                      @click="TransferWallet('to_business')" 
                      color="primary" 
                      rounded 
                      text
                      :small="$vuetify.breakpoint.xsOnly"
                      depressed 
                      class="no-caps"
                    >
                      {{ $t('convert_points') }}
                    </v-btn>
                  </div>
                  <div v-if="!card.balance.redeemable" class="ml-auto text-right">{{ $t('points_required_for_discount', { points: formatNumber(card.balance.pointsRequired) }) }}</div>
                  <div v-if="card.balance.redeemable" class="ml-auto">
                    <v-btn @click="dialogGenerateDiscountCode = true" color="primary" outlined rounded :small="$vuetify.breakpoint.xsOnly" depressed class="no-caps">
                      <v-icon left size="14">mdi-plus</v-icon>
                      {{ $t('discount') }}
                    </v-btn>
                  </div>
                </div>
              </v-card-actions>
              <!-- <v-row
                align="center"
                class="mx-4"
              >
                <span class="body-1 font-weight-medium" v-html="formatNumber(card.balance.points)"/> <v-icon right size="18">mdi-circle-multiple-outline</v-icon>
                <div class="grey--text ml-5"><span v-html="formatCurrency(card.balance.value, card.balance.currency)"/></div>
              </v-row>
              <v-card-text>
                <div class="subtitle-1 mb-3" v-if="card.short_description" v-html="card.short_description"/>
                <div class="body-2">
                  <div v-if="!card.balance.redeemable">{{ $t('points_required_for_discount', { points: formatNumber(card.balance.pointsRequired) }) }}</div>
                  <div v-if="card.balance.redeemable"><v-btn @click="dialogGenerateDiscountCode = true" color="NavBg NavFg--text" large depressed class="no-caps">{{ $t('discount') }} <v-icon right size="14">mdi-arrow-right</v-icon></v-btn></div>
                </div>
              </v-card-text> -->

              <!-- <v-expansion-panels flat elevation="0">
                <v-expansion-panel>
                  <v-expansion-panel-header>{{ $t('rules_and_conditions') }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li>{{ $t('rules_1', { months: card.balance.rules.points_expiration_months }) }}</li>
                      <li>{{ $t('rules_2', { currency: card.balance.currency, points: formatNumber(card.balance.rules.points_per_currency), value: formatCurrency(card.balance.rules.points_per_currency * card.balance.rules.point_value, card.balance.currency) }) }}</li>
                      <li>{{ $t('rules_3', { minPoints: formatNumber(card.balance.rules.min_points_per_purchase), maxPoints: formatNumber(card.balance.rules.max_points_per_purchase) }) }}</li>
                      <li>{{ $t('rules_4', { minPoints: formatNumber(card.balance.rules.min_points_per_redemption), maxPoints: formatNumber(card.balance.rules.max_points_per_redemption) }) }}</li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels> -->

            </v-card>
            <v-card
              outlined
              class="mt-4 pr-5"
            >
              <v-timeline dense clipped>
                <v-timeline-item
                  v-for="(item, index) in history"
                  :key="index"
                  fill-dot
                  :color="(item.expired) ? ($store.state.app.dark) ? 'grey darken-2' : 'grey lighten-1' : (item.event == 'purchase') ? 'success' : 'error'"
                  :class="(item.expired) ? ($store.state.app.dark) ? 'grey--text text--darken-2' : 'grey--text text--lighten-1' : ''"
                  :icon="(item.event == 'purchase') ? 'mdi-circle-multiple-outline' : (item.event == 'discount') ? 'mdi-content-cut' : (item.event == 'transfer_point' ? 'mdi-account-convert' : 'mdi-arrow-top-right-bottom-left')"
                >
                  <v-row class="flex-nowrap justify-space-between">
                    <v-col cols class="history-title-container">
                      <div class="text-subtitle-1">
                        <span v-if="item.status_transfer_point"> {{ $t('give_friend') }} </span>
                        <span v-else-if="item.status_exchange_point"> {{ $t('convert_points') }} </span>
                        <span v-else-if="item.status_affiliate_invite"> {{ $t('invite_points') }} </span>
                        <span v-else-if="item.status_affiliate_coupon_invite"> {{ $t('invite_coupon_points') }} </span>
                        <span v-else-if="item.status_affiliate_new_invite"> {{ $t('register_points') }} </span>
                        <span v-else-if="item.status_review_coupon"> {{ $t('review_coupon') }} </span>
                        <span v-else> {{ $t(item.event) }} </span>
                      </div>
                      <div class="text-caption grey--text">
                        <span v-if="
                          !item.status_transfer_point && 
                          !item.status_exchange_point && 
                          !item.status_affiliate_invite && 
                          !item.status_affiliate_coupon_invite && 
                          !item.status_affiliate_new_invite &&
                          !item.status_review_coupon
                        ">
                          {{ formatCurrency(item.purchase_amount, card.balance.currency) }}
                        </span>
                        <span v-if="item.status_transfer_point && item.event == 'transfer_point'">
                          {{ $t('transfer_point_to') }} {{ item.transfer_point_to_user_name }}
                        </span>
                        <span v-if="item.status_exchange_point && item.event == 'exchange_point'">
                          {{ $t('transfer_point_to') }} {{ item.exchange_points_to_business_name }}
                        </span>
                        <span v-if="item.status_transfer_point && item.event == 'purchase'">
                          {{ $t('transfer_point_from') }} {{ item.transfer_point_from_user_name }}
                        </span>
                        <span v-if="item.status_exchange_point && item.event == 'purchase'">
                          {{ $t('transfer_point_from') }} {{ item.exchange_points_from_business_name }}
                        </span>
                      </div>
                      <div class="text-caption grey--text" v-if="item.event == 'purchase'">
                        <span v-if="item.points_used > 0">{{ $t('points_used', { points: formatNumber(item.points_used) }) }}</span>
                        <span v-if="!item.expired && item.points_available > 0">{{ $t('points_expire', { points: formatNumber(item.points_available),  expire: formatDate(item.expires_at, 'ago') }) }}</span>
                        <span v-if="item.expired && item.points_available > 0">{{ $t('points_expired', { points: formatNumber(item.points_available) }) }}</span>
                      </div>
                    </v-col>
                    <v-col class="history-point-container text-right" cols="auto">
                      <div class="history-point-value d-flex flex-column flex-sm-row flex-wrap justify-end align-end align-sm-baseline text-h6">
                        <div>
                          <span v-if="item.points > 0" v-html="item.event == 'purchase' ? '+' : '-'" />
                          <span>{{ formatNumber(item.points) }}</span>
                        </div>
                        <div class="ml-1 text-caption">
                          {{ $t('points').toLowerCase() }}
                        </div>
                      </div>
                      <div class="text-caption grey--text">{{ formatDate(item.created_at, 'ago') }}</div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
                <v-timeline-item 
                  v-if="load_more"
                  fill-dot
                  color="info"
                  icon="mdi-refresh"
                >
                  <v-btn 
                    color="grey" 
                    text 
                    :loading="loading_load_more"
                    :disabled="loading_load_more"
                    @click.native="loadMore()" 
                    class="title float-right no-caps mt-4"
                  >
                    {{ $t('load_more') }}
                  </v-btn>
                </v-timeline-item>
              </v-timeline>
              
            </v-card>
          </div>
        </div>
      </v-card>
    </v-container>

    <v-dialog v-model="dialogGenerateDiscountCode" max-width="360" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable>
      <ValidationObserver ref="generateDiscountCodeForm" v-slot="{ invalid }">
        <v-form 
          id="generateDiscountCodeForm"
          lazy-validation
          @submit.prevent="generateDiscountCode()"
          autocomplete="off"
          method="post"
        >
          <v-card>
            <v-card-title>{{ $t('discount_code') }}
              <v-tooltip top max-width="340" color="">
                <template v-slot:activator="{ on }">
                  <v-icon color="" class="ml-2" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span v-html="$t('discount_code_help', { expiration_hours: card.discount_code_expiration_time_hours })"></span>
              </v-tooltip>
            </v-card-title>
            <v-divider v-if="step == 1"></v-divider>
            <v-card-text v-if="step == 1">
              <p class="text-subtitle-1 mb-4">{{ $t('enter_discount_amount', { minAmount: formatCurrency(card.balance.rules.min_amount_redeemable, card.balance.currency), maxAmount: formatCurrency(card.balance.rules.max_amount_redeemable, card.balance.currency) }) }}</p>
              <v-row>
                <v-col>
                  <x-text-field
                    type="number"
                    autofocus
                    v-model="generateDiscountCodeForm.amount"
                    ref="generateDiscountCodeForm.amount"
                    id="generateDiscountCodeForm.amount"
                    :name="$t('amount')"
                    :suffix="card.balance.currency"
                    :step="card.balance.rules.currency_step"
                    :rules="'required|between:' + card.balance.rules.min_amount_redeemable + ',' + card.balance.rules.max_amount_redeemable"
                    :hint="generateDiscountCodeForm.points + ' ' + $t('points').toLowerCase()"
                    persistent-hint
                  />
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    depressed 
                    rounded
                    color=""
                    class="mt-3 no-caps"
                    @click="generateDiscountCodeForm.amount = card.balance.rules.max_amount_redeemable"
                  >
                    {{ $t('max') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider v-if="step == 1"></v-divider>
            <v-card-actions v-if="step == 1">
              <v-spacer></v-spacer>
              <v-btn color="" class="no-caps" text rounded depressed @click="dialogGenerateDiscountCode = false">{{ $t('close') }}</v-btn>
              <v-btn color="primary" class="no-caps" rounded depressed :loading="generateDiscountCodeForm.loading" :disabled="generateDiscountCodeForm.loading || invalid" type="submit">{{ $t('get_code') }}</v-btn>
            </v-card-actions>
            <v-divider v-if="step == 2"></v-divider>
            <v-card-text v-if="step == 2">
              <p class="text-subtitle-1 mb-4">{{ $t('discount_code_details', { expires: generateDiscountCodeForm.expiresAt, points: generateDiscountCodeForm.calculatedPoints, amount: generateDiscountCodeForm.calculatedAmount, businessName: generateDiscountCodeForm.businessName }) }}</p>
              <x-text-field
                type="number"
                v-model="generateDiscountCodeForm.code"
                ref="generateDiscountCodeForm.code"
                id="generateDiscountCodeForm.code"
                readonly
                _append-icon="mdi-content-copy"
                _click:append="copyStringToClipboard(generateDiscountCodeForm.code)"
                :hide-details="true"
              />
              <v-card outlined class="d-flex flex-column mt-4" :style="{
                'max-width': qrWidth + 'px',
                'overflow': 'hidden',
                'margin': 'auto'
              } ">
                <qrcode v-if="!$vuetify.theme.dark" class="mt-0" :value="$init.config.schemeAndHost + '/business/redeem-points?c=' + generateDiscountCodeForm.code" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff00', dark: ($vuetify.theme.dark) ? '#ffffff' : '#000000' } }"></qrcode>
                <qrcode v-if="$vuetify.theme.dark" class="mt-0" :value="$init.config.schemeAndHost + '/business/redeem-points?c=' + generateDiscountCodeForm.code" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff', dark: '#000000' } }"></qrcode>
              </v-card>
            </v-card-text>
            <v-divider v-if="step == 2"></v-divider>
            <v-card-actions v-if="step == 2">
              <v-spacer></v-spacer>
              <v-btn color="" class="no-caps" text rounded depressed @click="step = 1; dialogGenerateDiscountCode = false">{{ $t('close') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>

  </div>
</template>
<script>
import { copyStringToClipboard } from '@/utils/helpers';
// import MenuCustomer from '@/components/ui/MenuCustomer.vue'
export default {
  // components: {
  //   MenuCustomer
  // },
  data: () => ({
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    loading_transfer_point: false,
    load_more: false,
    loading_load_more: false,
    page: 0,
    history: [],
    step: 1,
    dialogGenerateDiscountCode: false,
    exchange_points: false,
    exchange_points_data: [],
    generateDiscountCodeForm: {
      loading: false,
      code: '',
      amount: 0,
      points: 0,
      expiresAt: null,
      calculatedPoints: 0,
      calculatedAmount: 0,
      businessName: ''
    },
    card: null
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))
    this.getData()
  },
  methods: {
    copyStringToClipboard,
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number, currency) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
    getData() {
      // Query object
      this.loading = true
      let uuid = this.$route.params.uuid

      this.axios
        .get('/customer/card', { params: { locale: this.$i18n.locale, uuid: uuid }})
        .then(response => {
          this.card = response.data.card
          this.history = this.card.history
          if(this.history.length >= 25){
            this.load_more = true
          }
          else{
            this.load_more = false
          }
          this.generateDiscountCodeForm.amount = this.card.balance.rules.max_amount_redeemable
          this.generateDiscountCodeForm.points = this.formatNumber(this.card.balance.rules.max_points_redeemable / this.card.balance.rules.point_value)
          this.exchange_points = response.data.exchange_points
          this.exchange_points_data = response.data.exchange_points_data
          this.loading = false
        })
    },
    refreshData() {
      this.step = 1
      this.getData()
    },
    loadMore () {
      let uuid = this.$route.params.uuid
      this.loading_load_more = true
      this.page = this.page + 25
      this.axios
      .post('/customer/get-more-history', {
        uuid: uuid,
        page: this.page,
      })
      .then(response => {
        if (response.data.status === 'success') {
          // for(let e of response.data.history){
          //   this.history.push(e)
          // }
          let array = this.history
          this.history = array.concat(response.data.history)
          if(response.data.history.length >= 25){
            this.load_more = true
          }
          else{
            this.load_more = false
          }
        }
      })
      .catch(error => {
        this.$root.$snackbar(this.$t('not_found'))
      })
      .finally(() => { 
        this.loading_load_more = false
      })
    },
    openTransferWallet () {
      let uuid = this.$route.params.uuid
      this.loading_transfer_point = true
      this.axios
      .post('/customer/get-data-exchange-point', {
        uuid: uuid
      })
      .then(response => {
        if (response.data.status === 'success') {
          //console.log(response.data)
          if(response.data.exchange_points){
            this.$root.$SelectBusinessExchangePoint()
            .then((confirm) => {
              if (confirm.confirm) {
                //console.log(confirm)
                if(confirm.type == 'to_customer'){
                  this.$root.$TransferWallet(uuid)
                  .then((confirm_transfer_wallet) => {
                    if (confirm_transfer_wallet.confirm) {
                      this.loading = true
                      this.axios
                      .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
                      .then(response => {
                        this.wallet = response.data.wallet
                        this.loading = false
                      })
                    }
                  })  
                }
                else if(confirm.type == 'to_business'){
                  this.$root.$ExchangePoints(response.data.data,uuid)
                  .then((confirm_transfer_point) => {
                    if (confirm_transfer_point.confirm) {
                      //console.log(confirm_transfer_point)
                      this.loading = true
                      this.axios
                      .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
                      .then(response => {
                        this.wallet = response.data.wallet
                        this.loading = false
                      })
                    }
                  })  
                }
              }
            }) 
          }
          else{
            this.$root.$TransferWallet(uuid)
            .then((confirm) => {
              if (confirm.confirm) {
                this.loading = true
                this.axios
                .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
                .then(response => {
                  this.wallet = response.data.wallet
                  this.loading = false
                })
              }
            })  
          }
        }
      })
      .catch(error => {
        this.$root.$snackbar(this.$t('not_found'))
      })
      .finally(() => { 
        this.loading_transfer_point = false
      })
    },
    TransferWallet (type) {
      let uuid = this.$route.params.uuid
      if(type == 'to_customer'){
        this.$root.$TransferWallet(uuid)
        .then((confirm_transfer_wallet) => {
          if (confirm_transfer_wallet.confirm) {
            this.loading = true
            this.axios
            .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
            .then(response => {
              this.wallet = response.data.wallet
              this.loading = false
            })
          }
        })  
      }
      else if(type == 'to_business'){
        this.$root.$ExchangePoints(this.exchange_points_data,uuid)
        .then((confirm_transfer_point) => {
          if (confirm_transfer_point.confirm) {
            //console.log(confirm_transfer_point)
            this.loading = true
            this.axios
            .get('/customer/wallet', { params: { locale: this.$i18n.locale }})
            .then(response => {
              this.wallet = response.data.wallet
              this.loading = false
            })
          }
        })  
      }
    },
    generateDiscountCode () {
      this.generateDiscountCodeForm.loading = true
      this.axios
        .post('/customer/generate-discount-code', {
          locale: this.locale,
          uuid: this.card.uuid,
          amount: this.generateDiscountCodeForm.amount
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.generateDiscountCodeForm.code = response.data.code
            this.generateDiscountCodeForm.calculatedPoints = this.formatNumber(response.data.points)
            this.generateDiscountCodeForm.calculatedAmount = this.formatCurrency(response.data.amount, this.card.balance.currency)
            this.generateDiscountCodeForm.expiresAt = this.formatDate(response.data.expires_at, 'lll')
            this.generateDiscountCodeForm.businessName = response.data.name
            this.step = 2
          }
        })
        .catch(error => {
          if (error.response.data.status === 'error') {
            this.errorMsg = error.response.data.error
            let errors = error.response.data.errors || []

            for (let field in errors) {
              this.$refs['generateDiscountCodeForm.' + field].applyResult({
                errors: errors[field],
                valid: false,
                failedRules: {}
              })
            }
          }
        })
        .finally(() => { 
          this.generateDiscountCodeForm.loading = false
        })
    },
  },
  watch: {
    'generateDiscountCodeForm.amount': function () {
      this.generateDiscountCodeForm.points = this.formatNumber (this.generateDiscountCodeForm.amount / this.card.balance.rules.point_value)
    }
  },
  computed: {
    qrWidth: function () {
      let Width = parseInt(window.innerWidth)
      let w = Width
      const p = 32
      return (w > 599) ? 296 : w - p
    }
  }
}
</script>
<style scoped>
  .btn-back-wallet{
    position: relative;
    top: -10px;
  }

</style>